<template>
  <div class="device-resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-between
        "
      >
        <h1 class="h5 fw-medium mb-3">{{ $t('__residentDevice') }}</h1>
        <form @submit.prevent="searchDevice">
          <div class="input-group mb-3 rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchBedNumber')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t('__clearSearch') }}
            </button>
          </div>
        </form>
      </div>
      <div class="card">
        <div class="card-body" v-if="networks && items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :empty-text="$t('__notData')"
            :empty-filtered-text="$t('__notFindDevice')"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(resident_gender)="data">
              {{
                data.item.resident_gender === 1
                  ? $t('__male')
                  : data.item.resident_gender === 2
                  ? $t('__female')
                  : ''
              }}
            </template>
            <template v-slot:cell(network_id)="data">
              {{ getNetworkName(data.item.network_id) }}
            </template>
            <template v-slot:cell(connect_status)="data">
              <i
                :class="
                  data.item.state && data.item.state.connect_status
                    ? 'text-success'
                    : 'text-danger'
                "
                class="bi bi-circle-fill"
              ></i>
              <span
                v-if="
                  data.item.state &&
                    data.item.state.connect_status &&
                    data.item.state.lost_connect < 20
                "
              >
                {{ $t('__online') }}
              </span>
              <span
                v-else-if="
                  data.item.state &&
                    data.item.state.connect_status &&
                    data.item.state.lost_connect >= 20
                "
              >
                {{ $t('__connecting') }}
              </span>
              <span v-else>
                {{ $t('__offLine') }}
              </span>
            </template>
            <template v-slot:cell(version)="data">
              {{ data.item.version ? data.item.version : '' }}
            </template>
            <template v-slot:cell(status)="data">
              {{ data.item.status ? $t('__enable') : $t('__disable') }}
            </template>
            <template v-slot:cell(ended_at)="data">
              {{
                data.item.ended_at === null
                  ? ''
                  : $getTimeZoneDate(
                      data.item.ended_at_epoch,
                      timezone,
                      'YYYY-MM-DD'
                    )
              }}
            </template>
            <template v-slot:cell(updated_at)="data">
              {{
                data.item.last_change_epoch
                  ? $getTimeZoneDate(
                      data.item.last_change_epoch,
                      timezone,
                      'YYYY-MM-DD HH:mm'
                    )
                  : ''
              }}
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex" v-if="data.item.status">
                <button
                  @click="showEditDeviceModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1"></i>
                </button>
                <button
                  v-if="data.item.resident_name"
                  @click="showNotifyModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__notifySettings')"
                >
                  <i class="bi bi-bell mx-1"></i>
                </button>
                <button
                  v-if="data.item.resident_name"
                  @click="showDeviceErrorReportModal(data.item.id)"
                  type="button"
                  href=""
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__errorReport')"
                >
                  <i class="bi bi-chat-text mx-1"></i>
                </button>
                <button
                  @click="verifyClearRecordModal(data.item.id)"
                  type="button"
                  href=""
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__clearData')"
                >
                  <i class="bi bi-file-earmark-excel"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{
            $t('__paginationInfo', {
              startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
              entNub:
                currentPage == 1
                  ? totalRows > perPage
                    ? perPage
                    : totalRows
                  : perPage * (currentPage - 1) + perPage > totalRows
                  ? totalRows
                  : perPage * (currentPage - 1) + perPage,
              sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="editDevice"
      ref="editDeviceModal"
    >
      <div v-if="editDeviceModalData" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitDeviceForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ modalTitle.serial_id }}</span>
                <span class="d-block">{{ $t('__editResident') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="bed_number" class="form-label fw-medium">{{
                      $t('__bedNo')
                    }}</label>
                    <input
                      v-model="editDeviceForm.bed_number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.bed_number
                      }"
                      id="bed_number"
                      required
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.bed_number"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.bed_number"
                        :key="'bed_number' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="resident_name" class="form-label fw-medium">{{
                      $t('__residentName')
                    }}</label>
                    <input
                      v-model="editDeviceForm.resident_name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.resident_name
                      }"
                      id="resident_name"
                      required
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.resident_name"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.resident_name"
                        :key="'resident_name' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="residentAge" class="form-label fw-medium">{{
                      $t('__residentBirthday')
                    }}</label>
                    <input
                      v-model="editDeviceForm.birthday"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.birthday
                      }"
                      id="residentAge"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.birthday"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.birthday"
                        :key="'age' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label fw-medium">{{
                      $t('__residentGender')
                    }}</label>
                    <div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="editDeviceForm.resident_gender"
                          class="form-check-input"
                          :class="{
                            'is-invalid':
                              validate && validate.msg.resident_gender
                          }"
                          type="radio"
                          name="resident_gender"
                          id="resident_gender_male"
                          value="1"
                          required
                        />
                        <label
                          class="form-check-label"
                          for="resident_gender_male"
                          >{{ $t('__male') }}</label
                        >
                        <div
                          class="invalid-feedback"
                          v-if="validate && validate.msg.resident_gender"
                        >
                          <div
                            v-for="(msg, index) in validate.msg.resident_gender"
                            :key="'resident_gender' + index"
                          >
                            {{ msg }}
                          </div>
                        </div>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="editDeviceForm.resident_gender"
                          class="form-check-input"
                          :class="{
                            'is-invalid':
                              validate && validate.msg.resident_gender
                          }"
                          type="radio"
                          name="resident_gender"
                          id="resident_gender_female"
                          value="2"
                          required
                        />
                        <label
                          class="form-check-label"
                          for="resident_gender_female"
                          >{{ $t('__female') }}</label
                        >
                        <div
                          class="invalid-feedback"
                          v-if="validate && validate.msg.resident_gender"
                        >
                          <div
                            v-for="(msg, index) in validate.msg.resident_gender"
                            :key="'resident_gender' + index"
                          >
                            {{ msg }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="idNumber" class="form-label fw-medium">{{
                      $t('__idNumber')
                    }}</label>
                    <input
                      v-model="editDeviceForm.id_number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.id_number
                      }"
                      id="idNumber"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.id_number"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.id_number"
                        :key="'id_number' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="network_id" class="form-label fw-medium">{{
                      $t('__selectNetwork')
                    }}</label>
                    <select
                      v-model="editDeviceForm.network_id"
                      class="form-select"
                      :class="{
                        'is-invalid': validate && validate.msg.network_id
                      }"
                      id="network_id"
                    >
                      <option value="">N/A</option>
                      <option
                        v-for="network in networks"
                        :key="'network' + network.id"
                        :value="network.id"
                      >
                        {{ network.name }}
                      </option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.network_id"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.network_id"
                        :key="'network_id' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="note" class="form-label fw-medium">{{
                      $t('__note')
                    }}</label>
                    <textarea
                      v-model="editDeviceForm.note"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.note
                      }"
                      id="note"
                      rows="3"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.note"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.note"
                        :key="'note' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="errorReport"
      ref="errorReportModal"
    >
      <div v-if="modalTitle" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitErrorReportForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ modalTitle.serial_id }}</span>
                <span class="d-block">{{ $t('__errorReport') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="" class="form-label fw-medium">{{
                  $t('__errorType')
                }}</label>
                <select
                  v-model="errorReportModalData.type"
                  class="form-select"
                  required
                >
                  <option value="" selected>
                    {{ $t('__openThisSelectMenu') }}
                  </option>
                  <option value="1">{{ $t('__RR') }}</option>
                  <option value="2">{{ $t('__SIT') }}</option>
                  <option value="3">{{ $t('__LYB') }}</option>
                  <option value="4">{{ $t('__LVB') }}</option>
                  <option value="5">{{ $t('__restless') }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t('__wrongTime')
                }}</label>
                <VueCtkDateTimePicker
                  v-model="errorReportTime"
                  :label="$t('__wrongTime')"
                  :max-date="
                    $getTimeZoneDate(new Date(), timezone, 'YYYY-MM-DD HH:mm')
                  "
                  format="YYYY-MM-DDTHH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm"
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  :no-button-now="true"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label fw-medium">{{
                  $t('__description')
                }}</label>
                <textarea
                  v-model="errorReportModalData.description"
                  class="form-control"
                  id=""
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="notify"
      ref="notifyModal"
    >
      <div v-if="notifyModalData" class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-5">
            <div class="h4 modal-title fw-medium text-center">
              <span class="fs-6 d-block">
                {{ modalTitle.bed_number }}
              </span>
              {{ modalTitle.resident_name }}
            </div>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="h5 fw-medium mb-3">
              {{ $t('__notifySettings') }}
            </div>
            <div v-if="!hasnotifyData" class="text-danger mb-3">
              {{ $t('__notifyIsNotSet') }}
            </div>
            <div class="custom-tab-check d-flex">
              <div class="form-check">
                <input
                  v-model="notifySettingType"
                  class="form-check-input"
                  type="radio"
                  name="notifySettingType"
                  value="template"
                  id="notifySettingTypeTemplate"
                />
                <label
                  class="form-check-label fw-medium"
                  for="notifySettingTypeTemplate"
                >
                  {{ $t('__template') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  v-model="notifySettingType"
                  class="form-check-input"
                  type="radio"
                  name="notifySettingType"
                  value="custom"
                  id="notifySettingTypeCustom"
                />
                <label
                  class="form-check-label fw-medium"
                  for="notifySettingTypeCustom"
                >
                  {{ $t('__custom') }}
                </label>
              </div>
            </div>
            <div class="p-3 bg-white" v-if="notifySettingType === 'custom'">
              <div class="row align-items-center mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__notifyMethod') }}
                </div>
                <div class="col-lg-9">
                  <div class="form-check form-check-inline">
                    <input
                      v-model="notifyModalData.has_line"
                      class="form-check-input"
                      type="checkbox"
                      id="has_line"
                    />
                    <label class="form-check-label" for="has_line">LINE</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="notifyModalData.has_push"
                      class="form-check-input"
                      type="checkbox"
                      id="has_push"
                    />
                    <label class="form-check-label" for="has_push">{{
                      $t('__push')
                    }}</label>
                  </div>
                  <div v-if="notifyModalData.has_line">
                    <small
                      class="d-block text-danger"
                      v-if="LINEAuth && LINEAuth.length"
                      >{{
                        $t('__LINENotifyAuthorizedQuantity', {
                          qty: LINEAuth.length
                        })
                      }}</small
                    >
                    <small class="d-block text-danger" v-else>{{
                      $t('__LINENotifyAuthorizeNoAccount')
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row align-items-top mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__excludeRange') }}
                </div>
                <div class="col-lg-9">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="has_exclude"
                      v-model="notifyModalData.has_exclude"
                    />
                    <label class="form-check-label" for="has_exclude">{{
                      $t('__enable')
                    }}</label>
                  </div>
                  <small
                    class="text-danger d-block mt-1"
                    v-if="notifyModalData.has_exclude"
                    >{{ $t('__excludeRangeNote') }}</small
                  >
                  <div v-if="notifyModalData.has_exclude" class="pt-3">
                    <div
                      v-for="(excludeTime,
                      index) in notifyModalData.exclude_range"
                      :key="'excludeTime' + index"
                      class="row p-3 g-1 align-items-center"
                      :class="{ 'bg-light': index % 2 === 0 }"
                    >
                      <div class="col d-flex align-items-center mt-2 mt-lg-0">
                        <VueCtkDateTimePicker
                          :label="$t('__startTime')"
                          v-model="excludeTime.started_at"
                          only-time
                          format="HH:mm"
                          formatted="HH:mm"
                          right
                          color="#2CBDC0"
                          button-color="#2CBDC0"
                          :id="'excludeTimeStart_at' + index"
                        />
                        <span class="px-1">-</span>
                        <VueCtkDateTimePicker
                          :label="$t('__entTime')"
                          v-model="excludeTime.ended_at"
                          only-time
                          format="HH:mm"
                          formatted="HH:mm"
                          right
                          color="#2CBDC0"
                          button-color="#2CBDC0"
                          :id="'excludeTimeEnd_at' + index"
                        />
                      </div>
                      <div class="col-auto mt-2 mt-lg-0">
                        <button
                          type="button"
                          class="btn-close"
                          @click="delExcludeTime(index)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="text-end mt-2">
                    <button
                      v-if="
                        notifySettingType === 'custom' &&
                          notifyModalData.exclude_range.length < 5 &&
                          notifyModalData.has_exclude
                      "
                      class="btn btn-outline-primary rounded-pill"
                      @click="addExcludeTime"
                    >
                      {{ $t('__addExcludeTime') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row align-items-top mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__otherConditions') }}
                </div>
                <div class="col-lg-9">
                  <div
                    class="
                      form-check
                      d-flex
                      align-items-top align-items-sm-center
                      mb-3
                    "
                  >
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      id="has_silent_bed"
                      v-model="notifyModalData.has_silent_notify"
                    />
                    <label
                      class="form-check-label align-items-center"
                      for="has_silent_bed"
                    >
                      {{ $t('__afterNotification') }}
                      <span class="d-block d-sm-inline-block">
                        <input
                          :value="
                            secondsToMinutes(
                              notifyModalData.silent_notify_timeout
                            )
                          "
                          @change="
                            notifyModalData.silent_notify_timeout = minutesToSeconds(
                              $event.target.value
                            )
                          "
                          type="number"
                          class="
                            form-control
                            d-inline-block
                            mx-sm-2
                            me-2
                            mt-sm-0 mt-2
                          "
                          style="width: 100px"
                        />
                        {{ $t('__minutesLaterStartAgain') }}
                      </span>
                    </label>
                  </div>
                  <div
                    class="
                      form-check
                      d-flex
                      align-items-top align-items-sm-center
                      mb-3
                    "
                  >
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      id="has_silent_notify"
                      v-model="notifyModalData.has_silent_bed"
                    />
                    <label
                      class="form-check-label align-items-center"
                      for="has_silent_notify"
                    >
                      {{ $t('__backToBed') }}
                      <span class="d-block d-sm-inline-block">
                        <input
                          :value="
                            secondsToMinutes(notifyModalData.silent_bed_timeout)
                          "
                          @change="
                            notifyModalData.silent_bed_timeout = minutesToSeconds(
                              $event.target.value
                            )
                          "
                          type="number"
                          class="
                            form-control
                            d-inline-block
                            mx-sm-2
                            me-2
                            mt-sm-0 mt-2
                          "
                          style="width: 100px"
                        />
                        {{ $t('__withinMinutesWithoutNotification') }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  cms-style
                  side-tab
                "
              >
                <div class="left-col">
                  <ul
                    class="list-group flex-md-column flex-row"
                    ref="customList"
                  >
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LVBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LVBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LVBNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LYBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LYBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LYBNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#RRNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="RRNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__RRNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#restlessNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="restlessNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__restlessNotify") }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="right-col flex-fill">
                  <div class="tab-content cms-style h-100">
                    <div
                      class="tab-pane fade h-100"
                      id="LVBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave"
                                @click="clickNotifyLeave"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave"
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_leave">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave_action"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave_action"
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave_action"
                              >
                                {{ $t('__leaveTheBed') }}
                              </label>
                            </div>
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave_timeout"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave_timeout"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave_timeout"
                              >
                                {{ $t('__leaveTheBedTimeOut') }}
                              </label>
                            </div>
                            <div class="pt-4 mb-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_leave_timeout_max
                                "
                                :min="60"
                                :max="18000"
                                :format="SliderFormatMinuteMore"
                                :step="60"
                              />
                            </div>
                            <!-- <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave_timeout_max"
                                v-model="notifyModalData.notify_leave_site"
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave_timeout_max"
                              >
                                {{ $t("__SIT") }}
                              </label>
                            </div>
                            <div class="pt-4 mb-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_leave_site_max
                                "
                                :min="1"
                                :max="600"
                                :format="SliderFormatSecondLast"
                              />
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="LYBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_lying"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_lying"
                              />
                              <label
                                class="form-check-label"
                                for="notify_lying"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_lying">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label" for="">
                              {{ $t('__LYBTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_lying_max
                                "
                                :min="3600"
                                :max="21600"
                                :format="SliderFormatMinuteMore"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="RRNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_respiration"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_respiration"
                              />
                              <label
                                class="form-check-label"
                                for="notify_respiration"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="notifyModalData.notify_respiration"
                        >
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t('__rrValue') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                :value="sliderNotifyRespiration"
                                @update="changesliderNotifyRespiration"
                                :min="1"
                                :max="45"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t('__lastTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_respiration_duration
                                "
                                :min="60"
                                :max="7200"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="restlessNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="restlessNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__status") }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_restless"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_restless"
                              />
                              <label
                                class="form-check-label"
                                for="notify_restless"
                              >
                                {{ $t("__enable") }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_restless">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__notifyConditions") }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t("__restlessnessValue") }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_restless_max
                                "
                                :min="0"
                                :max="100"
                                :format="SliderFormatMore"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t("__lastTime") }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_restless_duration
                                "
                                :min="60"
                                :max="600"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 bg-white" v-else>
              <div class="row mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__openThisSelectMenu') }}
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col">
                      <div class="input-group mb-2">
                        <select
                          class="form-select border-0"
                          v-model="notifySettingTypeID"
                        >
                          <option
                            v-for="item in notifySetting"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{
                              item.is_template
                                ? $t('__' + item.name)
                                : item.name
                            }}
                          </option>
                        </select>
                        <button
                          class="btn btn-link p-2 text-dark"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="$t('__getTemplates')"
                          @click="getNotify"
                        >
                          <i class="bi bi-arrow-clockwise"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="btn btn-danger me-2 mb-2"
                        @click="toEditThisTemplate(notifySettingTypeID)"
                      >
                        {{ $t('__editThisTemplate') }}
                      </button>
                      <button
                        class="btn btn-outline-danger mb-2"
                        @click="copyNotifyData"
                      >
                        {{ $t('__copyThisTemplateToCustom') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__notifyMethod') }}
                </div>
                <div class="col-lg-9">
                  {{ notifyModalData.has_line ? 'LINE' : '' }}
                  <span
                    v-if="notifyModalData.has_line && notifyModalData.has_push"
                    >、</span
                  >
                  {{ notifyModalData.has_push ? $t('__push') : '' }}
                  <div v-if="notifyModalData.has_line">
                    <small
                      class="d-block text-danger"
                      v-if="LINEAuth && LINEAuth.length"
                      >{{
                        $t('__LINENotifyAuthorizedQuantity', {
                          qty: LINEAuth.length
                        })
                      }}</small
                    >
                    <small class="d-block text-danger" v-else>{{
                      $t('__LINENotifyAuthorizeNoAccount')
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row mb-3" v-if="notifyModalData.has_exclude">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__excludeRange') }}
                </div>
                <div class="col-lg-9">
                  <small class="text-danger d-block mb-1">{{
                    $t('__excludeRangeNote')
                  }}</small>
                  <div
                    v-for="(excludeTime,
                    index) in notifyModalData.exclude_range"
                    :key="'excludeTime' + index"
                    class="p-3"
                    :class="{ 'bg-light': index % 2 === 0 }"
                  >
                    <span>{{
                      excludeTime.started_at + '-' + excludeTime.ended_at
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="row mb-3 align-items-center"
                v-if="
                  notifyModalData.has_silent_bed ||
                    notifyModalData.has_silent_notify
                "
              >
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__otherConditions') }}
                </div>
                <div class="col-lg-9">
                  <div v-if="notifyModalData.has_silent_bed">
                    {{
                      $t('__afterNotificationInfo', {
                        nub: secondsToMinutes(
                          notifyModalData.silent_bed_timeout
                        )
                      })
                    }}
                  </div>
                  <div v-if="notifyModalData.has_silent_notify">
                    {{
                      $t('__backToBedInfo', {
                        nub: secondsToMinutes(
                          notifyModalData.silent_notify_timeout
                        )
                      })
                    }}
                  </div>
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  cms-style
                  side-tab
                "
              >
                <div class="left-col">
                  <ul
                    class="list-group flex-md-column flex-row templateList"
                    ref="templateList"
                  >
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_leave"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LVBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LVBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LVBNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_lying"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LYBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LYBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LYBNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_respiration"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#RRNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="RRNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__RRNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_restless"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#restlessNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="restlessNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__restlessNotify") }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="right-col flex-fill">
                  <div class="tab-content cms-style h-100">
                    <div
                      v-if="notifyModalData.notify_leave"
                      class="tab-pane fade h-100"
                      id="LVBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div v-if="notifyModalData.notify_leave_action">
                              {{ $t('__leaveTheBed') }}
                            </div>
                            <div v-if="notifyModalData.notify_leave_timeout">
                              {{
                                $t('__leaveTheBedTimeOut') +
                                  ' ' +
                                  secondsToMinutes(
                                    notifyModalData.notify_leave_timeout_max
                                  ) +
                                  ' ' +
                                  $t('__minute')
                              }}
                            </div>
                            <!-- <div v-if="notifyModalData.notify_leave_site">
                              {{
                                $t("__SIT") +
                                " " +
                                notifyModalData.notify_leave_site_max +
                                " " +
                                $("__second")
                              }}
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_lying"
                      class="tab-pane fade h-100"
                      id="LYBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            {{
                              $t('__LYBTime') +
                                ' > ' +
                                secondsToMinutes(
                                  notifyModalData.notify_lying_max
                                ) +
                                ' ' +
                                $t('__minute')
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_respiration"
                      class="tab-pane fade h-100"
                      id="RRNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div>
                              {{
                                $t('__rrValue') +
                                  ' &lt; ' +
                                  sliderNotifyRespiration[0] +
                                  '、 > ' +
                                  sliderNotifyRespiration[1]
                              }}
                            </div>
                            <div>
                              {{
                                $t('__lastTime') +
                                  ' ' +
                                  secondsToMinutes(
                                    notifyModalData.notify_respiration_duration
                                  ) +
                                  ' ' +
                                  $t('__minute')
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_restless"
                      class="tab-pane fade h-100"
                      id="restlessNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="restlessNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__notifyConditions") }}
                          </div>
                          <div class="col-md-9">
                            <div>
                              {{
                                $t("__restlessnessValue") +
                                " > " +
                                notifyModalData.notify_restless_max
                              }}
                            </div>
                            <div>
                              {{
                                $t("__lastTime") +
                                " " +
                                secondsToMinutes(
                                  notifyModalData.notify_restless_duration
                                ) +
                                " " +
                                $t("__minute")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t('__cancel') }}
            </button>
            <button
              type="button"
              class="btn btn-primary rounded-pill"
              @click="submitNotifyModal"
            >
              {{ $t('__save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="reboot"
      ref="rebootModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-medium text-center">
              <span class="d-block">{{ modalTitle.serial_id }}</span>
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            {{ $t('__rebootInfo') }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t('__cancel') }}
            </button>
            <button
              type="button"
              @click="submitReboot"
              class="btn btn-primary rounded-pill"
            >
              {{ $t('__rebootNow') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="verifyClearRecord"
      ref="verifyClearRecordModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="clearRecord">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ modalTitle.serial_id }}</span>
                <span class="d-block">{{
                  step === 1 ? $t('__backupData') : $t('__clearData')
                }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div v-if="step === 1">
                <h5>{{ $t('__exportNotifyRecord') }}</h5>
                <div class="mt-2 mb-4">
                  <div>{{ $t('__notifyBackup') }}</div>
                </div>
              </div>
              <div v-else>
                <h5>
                  {{ $t('__enterDeviceIDAndPassword') }}
                </h5>
                <div>
                  {{ $t('__verifyDeviceClearData') }}
                </div>
                <div class="mt-4 mb-3">
                  <label
                    for="verifyClearRecord-serial_id"
                    class="form-label fw-medium"
                    >{{ $t('__deviceId') }}</label
                  >
                  <input
                    v-model="verifyClearRecord.serial_id"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': validate && validate.msg.serial_id
                    }"
                    id="verifyClearRecord-serial_id"
                    required
                    :placeholder="$t('__deviceId')"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.serial_id"
                  >
                    {{ validate.msg.serial_id }}
                  </div>
                </div>
                <div class="mb-3">
                  <label
                    for="verifyClearRecord-password"
                    class="form-label fw-medium"
                    >{{ $t('__userPassword') }}</label
                  >
                  <div class="input-group">
                    <input
                      v-model="verifyClearRecord.password"
                      :type="show_password ? 'text' : 'password'"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.password
                      }"
                      id="verifyClearRecord-password"
                      required
                      :placeholder="$t('__userPassword')"
                    />
                    <span
                      class="input-group-text show-password"
                      role="button"
                      @click="showedPassword"
                    >
                      <i
                        class="bi"
                        :class="
                          show_password ? 'bi-eye-slash-fill' : 'bi-eye-fill'
                        "
                      ></i>
                    </span>
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.password"
                  >
                    {{ validate.msg.password }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                v-if="step === 1"
                type="button"
                class="btn btn-outline-secondary rounded-pill mt-2"
                @click="step += 1"
              >
                {{ $t('__skip') }}
              </button>
              <button
                v-else
                type="button"
                class="btn btn-outline-secondary rounded-pill mt-2"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button
                v-if="step === 1"
                type="button"
                class="btn btn-primary rounded-pill mt-2"
                @click="exportData"
              >
                {{ $t('__export') }}
              </button>
              <button v-else type="submit" class="btn btn-danger rounded-pill">
                {{ $t('__performCleanup') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import '@vueform/slider/themes/default.css'
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import { Tooltip, Modal, Tab } from 'bootstrap'
import i18n from '@/lang/lang.js'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { resident } from '@/http/api/resident.js'
import { network } from '@/http/api/network.js'
import { notify } from '@/http/api/notify.js'
import { notifyTemplate } from '@/http/api/notifyTemplate.js'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { line } from '@/http/api/line.js'
import { exportReport } from '@/http/api/export.js'

export default {
  name: 'DeviceResident',
  components: { HeaderMenu, VueCtkDateTimePicker, Slider },
  data () {
    return {
      show_password: false,
      step: 1,
      fields: [
        {
          key: 'serial_id',
          label: i18n.t('__deviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'bed_number',
          label: i18n.t('__bedNo'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'resident_name',
          label: i18n.t('__residentName'),
          class: 'align-middle',
          thStyle: 'min-width:80px',
          sortable: true
        },
        {
          key: 'resident_gender', // 1 男 2 女
          label: i18n.t('__residentGender'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'id_number',
          label: i18n.t('__idNumber'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'current_age',
          label: i18n.t('__age'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'note',
          label: i18n.t('__note'),
          class: 'align-middle'
        },
        {
          key: 'network_id',
          label: i18n.t('__networkName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'connect_status',
          label: i18n.t('__networkConnectionStatus'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'version',
          label: i18n.t('__version'),
          class: 'align-middle',
          thStyle: 'max-width:100px',
          sortable: true
        },
        {
          key: 'status',
          label: i18n.t('__status'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'ended_at',
          label: i18n.t('__endedAt'),
          class: 'align-middle',
          thStyle: 'min-width:100px',
          sortable: true
        },
        {
          key: 'updated_at',
          label: i18n.t('__updated'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ],
      filter: null,
      filterOn: ['serial_id', 'bed_number'],
      items: null,
      networks: null,
      search: null,
      editDeviceModalData: null,
      errorReportTime: null,
      errorReportModalData: {
        type: 1,
        trouble_time: null,
        description: null
      },
      hasnotifyData: false,
      notifyModalData: {
        exclude_range: [],
        has_line: 0,
        has_push: 0,
        has_exclude: 0,
        has_silent_bed: 0,
        silent_bed_timeout: 300,
        has_silent_notify: 0,
        silent_notify_timeout: 600,
        notify_leave: 0,
        notify_leave_action: 0,
        notify_leave_timeout: 0,
        notify_leave_timeout_max: 900,
        notify_leave_site: 0,
        notify_leave_site_max: 10,
        notify_lying: 0,
        notify_lying_max: 7800,
        notify_restless: 0,
        notify_restless_max: 45,
        notify_restless_duration: 300,
        notify_respiration: 0,
        notify_respiration_min: 12,
        notify_respiration_max: 28,
        notify_respiration_duration: 600
      },
      modalTitle: {
        id: null,
        serial_id: null,
        bed_number: null,
        resident_name: null
      },
      exportTaskId: null,
      bootstrap_tooltip: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      editDeviceId: null,
      editDeviceForm: {
        bed_number: null,
        resident_name: null,
        age: null,
        resident_gender: null,
        id_number: null,
        network_id: '',
        note: null
      },
      verifyClearRecord: {
        serial_id: null,
        password: null,
        before_at: null
      },
      showNotifySettingID: null,
      notifySettingType: 'template', // custom
      notifySettingTypeID: 1,
      notify_setting_id: null,
      notifySetting: null,
      bootstrap_modal: null,
      bootstrap_template_tab: [],
      bootstrap_custom_tab: [],
      SliderFormatMore: val => {
        return i18n.t('__moreThanThe') + Math.round(val)
      },
      SliderFormatMinuteMore: val => {
        return (
          i18n.t('__moreThanThe') + Math.round(val / 60) + i18n.t('__minute')
        )
      },
      SliderFormatMinuteLess: val => {
        return i18n.t('__lessThan') + Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatMinute: val => {
        return Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatSecond: val => {
        return Math.round(val) + i18n.t('__second')
      },
      SliderFormatSecondLast: val => {
        return i18n.t('__last') + Math.round(val) + i18n.t('__second')
      },
      validate: null,
      LINEAuth: null,
      oldWifi: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo', 'timezone']),
    ...mapGetters(['timeZoneOffset']),
    sliderNotifyRespiration () {
      if (this.notifyModalData) {
        return [
          this.notifyModalData.notify_respiration_min,
          this.notifyModalData.notify_respiration_max
        ]
      } else {
        return null
      }
    },
    errorReportTimeIsTaiwanTimeZone () {
      const vm = this
      return vm.$getTimeZoneDate(
        new Date(vm.errorReportTime + vm.timeZoneOffset),
        'Asia/Taipei',
        'YYYY-MM-DD HH:mm'
      )
    }
  },
  watch: {
    userInfo () {
      this.getNetwork()
      this.getDevice()
      this.getNotify()
    },
    notifySettingTypeID () {
      this.getNotifySetting()
    },
    notifySettingType () {
      const vm = this
      vm.$nextTick(function () {
        vm.getNotifySetting()
        vm.initBootstrapTab()
        vm.initBootstrapTooltip()
      })
    },
    items (data) {
      this.totalRows = data.length
      this.initBootstrapTooltip()
    },
    editDeviceForm: {
      handler: function () {
        this.validate = null
      },
      deep: true
    },
    verifyClearRecord: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    showedPassword () {
      this.show_password = !this.show_password
    },
    clickNotifyLeave () {
      if (this.notifyModalData.notify_leave) {
        this.notifyModalData.notify_leave_action = 0
        this.notifyModalData.notify_leave_timeout = 0
      } else {
        this.notifyModalData.notify_leave_action = 1
        this.notifyModalData.notify_leave_timeout = 1
      }
    },
    getLINEAuth () {
      const vm = this
      line.lineAuth(vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.LINEAuth = res.data.data
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getDevice () {
      const vm = this
      vm.Loading()
      resident.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.items = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    getNetwork () {
      const vm = this
      network.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.networks = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getNotify () {
      const vm = this
      notifyTemplate.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          let hasSettingID = false
          vm.notifySetting = []
          if (data.length) {
            data.forEach(item => {
              if (item.name) {
                vm.notifySetting.push(item)
                if (vm.notifySettingTypeID === item.id) {
                  hasSettingID = true
                }
              }
            })
            vm.notifySettingTypeID = hasSettingID
              ? vm.notifySettingTypeID
              : vm.notifySetting[0].id
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getNetworkName (id) {
      const vm = this
      var name = ''
      vm.networks.forEach(network => {
        if (network.id === id) {
          name = network.name
          return false
        }
      })
      return name
    },
    getNotifySetting () {
      const vm = this
      if (vm.notifySettingType === 'template') {
        vm.notifySetting.forEach(item => {
          if (item.id === vm.notifySettingTypeID) vm.notifyModalData = item
        })
      }
    },
    submitDeviceForm () {
      const vm = this
      vm.Loading()
      resident
        .update(vm.editDeviceId, vm.editDeviceForm, vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDevice()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
            if (
              (vm.oldWifi === null && vm.editDeviceForm.network_id === '') ||
              (vm.oldWifi === '' && vm.editDeviceForm.network_id === null)
            ) {
              return
            }

            if (vm.oldWifi !== vm.editDeviceForm.network_id) {
              vm.rebootModal()
            }
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
    },
    submitErrorReportForm () {
      const vm = this
      vm.Loading()
      vm.errorReportModalData.trouble_time = vm.errorReportTimeIsTaiwanTimeZone
      resident
        .errorReport(vm.editDeviceId, vm.errorReportModalData, vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDevice()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__submitSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
    },
    submitNotifyModal () {
      const vm = this
      console.log('submitNotifyModal')
      if (vm.notifySettingType === 'template') {
        console.log('template')
        notify
          .useSharedNotify(
            vm.showNotifySettingID,
            {
              notify_setting_id: vm.notifySettingTypeID
            },
            vm.token
          )
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.Alerted()
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.Alerted()
              vm.validate = res.data.validate
            }
            vm.Loaded()
          })
      } else {
        console.log('custom')

        delete vm.notifyModalData.agency_id
        delete vm.notifyModalData.created_at
        delete vm.notifyModalData.id
        delete vm.notifyModalData.is_shared
        delete vm.notifyModalData.is_template
        delete vm.notifyModalData.name
        delete vm.notifyModalData.updated_at

        notify
          .useCustomNotify(vm.showNotifySettingID, vm.notifyModalData, vm.token)
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.Alerted()
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.Alerted()
              vm.validate = res.data.validate
            }
            vm.Loaded()
          })
      }
    },
    submitReboot () {
      const vm = this
      vm.Loading()
      resident.reboot(vm.editDeviceId, vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__rebootSuccess')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__rebootError'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Loaded()
        vm.Alerted()
        vm.bootstrap_modal.hide()
      })
    },
    rebootModal () {
      const vm = this
      vm.bootstrap_modal = new Modal(vm.$refs.rebootModal)
      vm.bootstrap_modal.show()
    },
    changesliderNotifyRespiration (vale) {
      this.notifyModalData.notify_respiration_min = vale[0]
      this.notifyModalData.notify_respiration_max = vale[1]
    },
    showEditDeviceModal (id) {
      const vm = this
      vm.items.forEach(item => {
        if (item.id === id) {
          vm.editDeviceModalData = item
          vm.editDeviceId = id
          vm.editDeviceForm.bed_number = item.bed_number
          vm.editDeviceForm.resident_name = item.resident_name
          vm.editDeviceForm.birthday = item.birthday
          vm.editDeviceForm.resident_gender = item.resident_gender
          vm.editDeviceForm.id_number = item.id_number
          vm.editDeviceForm.network_id = item.network_id || ''
          vm.editDeviceForm.note = item.note
          vm.modalTitle.serial_id = item.serial_id

          vm.oldWifi = item.network_id
        }
      })
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.editDeviceModal)
        vm.bootstrap_modal.show()
      })
    },
    showDeviceErrorReportModal (id) {
      const vm = this
      vm.errorReportModalData.type = 1
      vm.errorReportModalData.trouble_time = null
      vm.errorReportModalData.description = null
      vm.editDeviceId = id
      vm.items.forEach(item => {
        if (item.id === id) {
          vm.modalTitle.serial_id = item.serial_id
        }
      })
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.errorReportModal)
        vm.bootstrap_modal.show()
      })
    },
    showNotifyModal (id) {
      const vm = this
      async function getNotifyDate () {
        await vm.getNotify()
        vm.Loading()
        vm.showNotifySettingID = id
        vm.getLINEAuth()
        notify.get(id, vm.token).then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            const data = res.data.data.notify
            if (data) {
              vm.hasnotifyData = true
              if (data.is_shared || data.is_template) {
                vm.notifySettingType = 'template'
                vm.notifySettingTypeID = data.id
                vm.getNotifySetting()
              } else {
                vm.notifySettingType = 'custom'
                vm.notifyModalData = data
              }
            } else {
              vm.hasnotifyData = false
              vm.notifySettingType = 'template'
              // vm.notifySettingTypeID = vm.notifySetting[0].id
              vm.getNotifySetting()
            }
            vm.modalTitle.bed_number = res.data.data.bed_number
            vm.modalTitle.resident_name = res.data.data.resident_name
            vm.$nextTick(() => {
              vm.bootstrap_modal = new Modal(vm.$refs.notifyModal)
              vm.bootstrap_modal.show()
              vm.initBootstrapTab()
              vm.initBootstrapTooltip()
            })
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errors.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      }
      getNotifyDate()
    },
    verifyClearRecordModal (id) {
      const vm = this
      vm.items.forEach(item => {
        if (item.id === id) {
          vm.editDeviceId = id
          vm.modalTitle.serial_id = item.serial_id
        }
      })
      vm.modalTitle.id = id
      vm.step = 1
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.verifyClearRecordModal)
        vm.bootstrap_modal.show()
      })
    },
    exportData () {
      const vm = this
      vm.Loading()
      exportReport
        .singleNotifyReport(vm.modalTitle.id, '', vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.exportTaskId = res.data.taskId
            vm.exportFile()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg
            })
            vm.Alerted()
          }
        })
    },
    exportFile () {
      const vm = this
      exportReport.getExportFile(vm.exportTaskId, vm.token).then(res => {
        if (res.status <= 201) {
          switch (res.data.status) {
            case 'failed':
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg
              })
              vm.Alerted()
              vm.Loaded()
              break
            case 'running':
              setTimeout(() => {
                vm.exportFile()
              }, 3000)
              break
            case 'success':
              window.open(
                /https/.test(res.data.path)
                  ? res.data.path
                  : window.location.origin + res.data.path,
                '_blank'
              )
              vm.Loaded()
              vm.step = 2
              break
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg
          })
          vm.Alerted()
        }
      })
    },
    clearRecord () {
      const vm = this
      if (vm.verifyClearRecord.serial_id !== vm.modalTitle.serial_id) {
        vm.validate = {
          msg: {
            serial_id: i18n.t('裝置序號輸入錯誤')
          }
        }
        return
      }
      vm.Loading()
      var config = Object.assign({}, vm.token)

      config.params = {
        before_at: vm.$getTimeZoneDate(
          new Date(),
          'Asia/Taipei',
          'YYYY-MM-DD HH:mm:ss'
        ),
        password: vm.verifyClearRecord.password
      }
      resident.clearRecord(vm.editDeviceId, config).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__editSuccess')
          })
          vm.Alerted()
          vm.bootstrap_modal.hide()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg
          })
          vm.Alerted()
          vm.validate = {
            msg: {
              password: res.data.errMsg
            }
          }
        }
        vm.Loaded()
      })
    },
    addExcludeTime () {
      this.notifyModalData.exclude_range.push({
        started_at: null,
        ended_at: null
      })
    },
    delExcludeTime (index) {
      this.notifyModalData.exclude_range.splice(index, 1)
    },
    initBootstrapTab () {
      const vm = this
      if (vm.notifySettingType === 'template' && vm.$refs.templateList) {
        vm.bootstrap_template_tab = []
        const triggerTabList = [].slice.call(
          vm.$refs.templateList.querySelectorAll('button')
        )
        triggerTabList.forEach(function (triggerEl, index) {
          vm.bootstrap_template_tab.push(new Tab(triggerEl))
          triggerEl.addEventListener('click', function (event) {
            event.preventDefault()
            vm.bootstrap_template_tab[index].show()
          })
        })
        vm.bootstrap_template_tab[0].show()
      } else if (vm.notifySettingType === 'custom' && vm.$refs.customList) {
        vm.bootstrap_custom_tab = []
        const triggerTabList = [].slice.call(
          vm.$refs.customList.querySelectorAll('button')
        )
        triggerTabList.forEach(function (triggerEl, index) {
          vm.bootstrap_custom_tab.push(new Tab(triggerEl))
          triggerEl.addEventListener('click', function (event) {
            event.preventDefault()
            vm.bootstrap_custom_tab[index].show()
          })
        })
        vm.bootstrap_custom_tab[0].show()
      }
    },
    initBootstrapTooltip () {
      const vm = this
      vm.$nextTick(function () {
        vm.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
              trigger: 'hover'
            })
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
              vm.hideTooltip()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach(tooltip => {
          tooltip.hide()
        })
      }
    },
    searchDevice () {
      this.filter = this.search
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    minutesToSeconds (time) {
      return time * 60
    },
    secondsToMinutes (time) {
      return time / 60
    },
    copyNotifyData () {
      const vm = this
      vm.notifySetting.forEach(item => {
        if (item.id === vm.notifySettingTypeID) {
          vm.notifyModalData = item
        }
      })
      vm.notifySettingType = 'custom'
    },
    toEditThisTemplate (id) {
      const routeUrl = this.$router.resolve({
        name: 'NotifyTemplate',
        params: { id: id }
      })
      window.open(routeUrl.href, '_blank')
    }
  },
  created () {},
  mounted () {
    if (this.userInfo) {
      this.getNetwork()
      this.getDevice()
      this.getNotify()
    }
  }
}
</script>

<style lang="scss">
#notify {
  .modal-content {
    background-color: #f3f3f3;
  }
}
.custom-tab-check {
  .form-check {
    position: relative;
    width: 105px;
    height: 34px;
    margin-bottom: 20px;
    &-input {
      opacity: 0;
      &:checked + .form-check-label {
        background-color: $primary;
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 90%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $primary transparent transparent transparent;
        }
      }
    }
    &-label {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      .form-check-label {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.slider-connect {
  background: $primary;
}
.slider-tooltip {
  border: 1px solid $dark;
  background: $dark;
}
</style>
