import { axiosConfig } from './axios-config.js'
export const resident = {
  // 取得居民清單
  get: (id, token) => axiosConfig.get(`/api/resident/${id || ''}`, token),

  // 更新居民資訊
  update: (id, data, token) => axiosConfig.patch(`/api/resident/${id}`, data, token),

  // 錯誤回報
  errorReport: (id, data, token) => axiosConfig.post(`/api/resident/${id}/report`, data, token),

  // 重啟裝置
  reboot: (id, token) => axiosConfig.post(`/api/resident/${id}/reboot`, '', token),

  // 清除過去資料
  clearRecord: (id, token) => axiosConfig.delete(`/api/resident/${id}/record`, token)
}
